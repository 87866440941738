import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { Heading1, Heading2 } from '../../styles/vars/textStyles.style'

// Components
import Button from '../Button'
import BackArrow from '../WellbeingModules/BackArrow'

import Grid from '../Grid/index'
import GridItem from '../GridItem/index'

import Gif from './assets/hero.gif'

import { HeroSection, Explore } from './index.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import PageTransitionLink from '../PageTransitionLink'
import styled from 'styled-components'

gsap.registerPlugin(ScrollToPlugin)

const Hero = ({ home, title, errorPage, button }) => {
  const exploreRef = useRef(null)

  const sectionDelay = 0.5

  useEffect(() => {
    const { current } = exploreRef

    const click = e => {
      e.preventDefault()
      let nextSection = document.querySelector('#colour-block')
      gsap.to(window, { scrollTo: nextSection })
    }

    if (current) {
      current.addEventListener('click', click)
    }

    return () => {
      if (current) {
        current.removeEventListener('click', click)
      }
    }
  }, [])

  return (
    <HeroSection errorPage={errorPage} home={home}>
      <Grid>
        {home ? (
          <GridItem mobile={14} mobileStart={2} tabletL={10} tabletLStart={4}>
            <AnimateFadeIn delay={sectionDelay}>
              <Heading1>{title}</Heading1>
              <Button
                theme={1}
                text={button.buttonText}
                to={button.buttonLink}
                hasborder
              />
            </AnimateFadeIn>
          </GridItem>
        ) : (
          <GridItem
            mobile={14}
            mobileStart={2}
            tabletL={8}
            tabletLStart={5}
            desk={6}
            deskStart={6}
          >
            <AnimateFadeIn delay={sectionDelay}>
              <FlexContainer>
                <BackArrow/>
                <Heading2 as={`h1`}>{title}</Heading2>
              </FlexContainer>
            </AnimateFadeIn>
          </GridItem>
        )}
        {home ? (
          <GridItem mobile={16}>
            <AnimateFadeIn delay={sectionDelay * 4}>
              <Explore>
                <img src={Gif} height={30} width={12} alt="" />
                <a href="/" ref={exploreRef}>
                  Explore
                </a>
              </Explore>
            </AnimateFadeIn>
          </GridItem>
        ) : (
          ``
        )}
        {errorPage ? (
          <GridItem mobile={16}>
            <PageTransitionLink to={`/`} underline={true} hasborder>
              Home
            </PageTransitionLink>
          </GridItem>
        ) : (
          ``
        )}
      </Grid>
    </HeroSection>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export default Hero
