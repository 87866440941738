import styled from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const HeroSection = styled.div`
  padding-top: 19.7rem;
  position: relative;
  text-align: center;

  ${({ home }) =>
    home === false
      ? `
    padding-bottom: 10rem;

    ${mq.tabletL} {
      padding-top: 15vmin;
      padding-bottom: 17vmin;
    }
    
    ${mq.deskL} {
      padding-top: 18.2rem;
      padding-bottom: 25.7rem;
    }`
      : `
    padding-top: 28vmin;
    
    ${mq.tabletL} {
      padding-bottom: 0;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 8.6rem);
    }`}

  ${({ errorPage }) => {
    return errorPage === true ? `min-height: 95vh;` : ``
  }}
  
  h1 {
    margin-bottom: 0;
  }
`
export const Explore = styled.div`
  padding-top: 21.4rem;
  margin-bottom: 6rem;
  text-align: center;

  ${mq.tabletL} {
    display: none;
  }

  img {
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    border-bottom: none;
    font-family: ${font.secondary.family};
    text-transform: uppercase;
    text-decoration: none;
  }
`
